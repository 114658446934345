// Main Slider
.main-slider {
  .item {
    background-color: var(--black);
    position: relative;
    width: 100%;
    @include responsive-ratio(1, 1.5);
    max-height: 75vh;
    overflow: hidden;
    pointer-events: none;
    @include mappy-bp(med) {
      @include responsive-ratio(16, 9);
      max-height: 75vh;
    }
    .video {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
    .preview {
      background-color: theme-var($--theme-background);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: 5;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .35s linear;
      img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    &.is-playing {
      .preview {
        opacity: 0;
        pointer-events: none;
      }
    }
    &.is-selected{
      pointer-events: all;
    }
  }
  .flickity-page-dots {
    display: none;
    bottom: 0;
    padding: 20px;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    .dot {
      background-color: var(--white);
      opacity: .7;
      &.is-selected {
        background-color: theme-var($--theme-primary);
      }
      @media (hover: hover) {
        transition: $transition-base;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
