// Map
.map {
  @include responsive-ratio(1, 1);
  #map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
}
