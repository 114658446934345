/**
 * Layout
 */

body > .container{
  position: relative;
}

.wrapper{
  margin: 0 auto;
  // padding-bottom: calc(var(--grid-gap) * 2);
  position: relative;
  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 100%;
    // background-color: theme-var($--theme-primary);
    background-image: linear-gradient(36deg, transparent 0%, transparent 52%, theme-var($--theme-primary) 100%);
    pointer-events: none;
    z-index: 100;
    @include mappy-bp(med) {
      padding-top: 40%;
      background-image: linear-gradient(10deg, transparent 0%, transparent 52%, theme-var($--theme-primary) 100%);
    }
    @include mappy-bp(large) {
      padding-top: 30%;
      background-image: linear-gradient(6deg, transparent 0%, transparent 52%, theme-var($--theme-primary) 100%);
    }
  }
}

.main > *:last-child{
  // margin-bottom: 0;
  // padding-bottom: 0;
}

.max-w{
  @include max-width-center(var(--max-width));
  padding-left: 20px;
  padding-right: 20px;
  @include mappy-bp(med) {
    padding-left: var(--grid-gap);
    padding-right: var(--grid-gap);
    &-no-pad{
      padding-left: 0;
      padding-right: 0;
    }
  }
  &-s{
    @include max-width-center(var(--max-width-s));
    padding-left: 20px;
    padding-right: 20px;
    @include mappy-bp(med) {
      padding-left: var(--grid-gap);
      padding-right: var(--grid-gap);
      &-no-pad{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &-xs{
    @include max-width-center(var(--max-width-xs));
    padding-left: 20px;
    padding-right: 20px;
    @include mappy-bp(med) {
      padding-left: var(--grid-gap);
      padding-right: var(--grid-gap);
      &-no-pad{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &-xxs{
    @include max-width-center(var(--max-width-xxs));
    padding-left: 20px;
    padding-right: 20px;
    @include mappy-bp(med) {
      padding-left: var(--grid-gap);
      padding-right: var(--grid-gap);
      &-no-pad{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &-md{
    @include max-width-center(var(--max-width-md));
    padding-left: 20px;
    padding-right: 20px;
    @include mappy-bp(med) {
      padding-left: var(--grid-gap);
      padding-right: var(--grid-gap);
      &-no-pad{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &-l{
    @include max-width-center(var(--max-width-l));
    padding-left: 20px;
    padding-right: 20px;
    @include mappy-bp(med) {
      padding-left: var(--grid-gap);
      padding-right: var(--grid-gap);
      &-no-pad{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &-xl{
    @include max-width-center(var(--max-width-xl));
    padding-left: 20px;
    padding-right: 20px;
    @include mappy-bp(med) {
      padding-left: var(--grid-gap);
      padding-right: var(--grid-gap);
      &-no-pad{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &-xxl{
    @include max-width-center(var(--max-width-xxl));
    padding-left: 20px;
    padding-right: 20px;
    @include mappy-bp(med) {
      padding-left: var(--grid-gap);
      padding-right: var(--grid-gap);
      &-no-pad{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  @include mappy-bp(small med) {
    &-no-pad-s{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

*:focus{
  box-shadow: none;
  outline: none;
}

.drop-shadow{
  filter: drop-shadow(0 10px 30px hsl(var(--black-hsl), .2));
}

::selection {
  color: theme-var($--theme-background);
  background: theme-var($--theme-primary);
}

.flipped{
  display: inline-block;
  letter-spacing: 1px;
  transform: scale(-1, 1);
}

.spacer{
  height: var(--navbar-height-small);
  @include mappy-bp(med) {
    height: var(--navbar-height-med);
  }
  @include mappy-bp(large) {
    height: var(--navbar-height-large);
  }
}
