// Timeline
.timeline {
  --dotW: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  isolation: isolate;
  @include mappy-bp(med) {
    --dotW: 25px;
    padding: 0 var(--dotW);
  }
  &--wrap {
    --dotW: 15px;
    @include mappy-bp(med) {
      --dotW: 25px;
    }
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: relative;
    z-index: 0;
    margin-bottom: calc(var(--grid-gap) + var(--dotW));
    @include mappy-bp(med) {
      margin-bottom: calc(var(--grid-gap) / 2 + var(--dotW));
    }
    .filling-line {
      position: absolute;
      height: 2px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: var(--theme-primary);
      width: 0;
      z-index: 0;
    }
  }
  .input {
    --dotW: 15px;
    @include mappy-bp(med) {
      --dotW: 25px;
    }
    width: 100%;
    position: relative;
    height: var(--dotW);
    .dot {
      width: var(--dotW);
      height: var(--dotW);
      background-color: var(--theme-primary);
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: $transition-base;
      z-index: 1;
      &:hover {
        cursor: pointer;
      }
    }
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--white);
      width: 50%;
      height: 2px;
    }
    &:before {
      left: 0;
    }
    &::after {
      right: 0;
    }
    span {
      width: 1px;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
      color: var(--theme-primary);
      font-size: 15px;
      &::before,
      &::after {
        visibility: visible;
        position: absolute;
        left: 50%;
      }
      &::after {
        content: attr(data-year);
        top: var(--dotW);
        transform: translateX(-50%);
        font-size: 13px;
        font-weight: 700;
        @include mappy-bp(med) {
          font-size: 15px;
        }
      }
    }
    &.active {
      .dot {
        background-color: var(--theme-primary);
      }
      span {
        color: var(--theme-primary);
      }
      & ~ .input {
        span {
          color: var(--white);
        }
        .dot {
          background-color: var(--white);
        }
      }
    }
  }

  &--description {
    width: 100%;
    max-width: calc(100% - 40px);
    height: 100px;
    color: var(--white);
    text-align: center;
    position: relative;
    .title {
      width: 100%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      opacity: 0;
      margin-bottom: 0;
      transition: $transition-base;
      &.active {
        opacity: 1;
      }
    }
  }
}
