/**
 * Responsive ratio
 *
 * Usage:
 * @include responsive-ratio(16,9);
 * @include responsive-ratio(4,3);
 */

@mixin responsive-ratio($x,$y, $pseudo: true) {
  $padding: unquote(calc($y / $x) * 100 + '%');
  overflow-x: hidden;
  @if $pseudo {
    &:before {
      content: "";
      float: left;
      position: relative;
      padding-top: $padding;
    }
    &:after{
      content: " ";
      display: table;
      clear: left;
    }
  } @else {
    padding-top: $padding;
  }
  & > .inner {
    display: flex;
    position: absolute;
    // inset: 0;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    overflow: hidden;
  }
}
