/**
 * Global
 *
 */

$font-family: 'Red Hat Display', sans-serif, -apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-size-base: 1rem !default;
$line-height-base: 1.4 !default;

// Colors
$--theme-primary: --theme-primary;
$--theme-secondary: --theme-secondary;
$--theme-background: --theme-background;
$black: --black;
$white: --white;
$light-grey: hsl(0, 0%, 98%);
$dark-grey: hsl(0, 0%, 78%);
$grey: hsl(0, 0%, 95%);

// Theme Divisione Dentale
$theme-map-dental: (
  $--theme-primary: hsl(180, 53%, 34%),
  $--theme-background: hsl(0, 0%, 97%),
);
// Theme Divisione Orafa
$theme-map-goldsmith: (
  $--theme-primary: hsl(54, 86%, 41%),
  $--theme-background: hsl(230, 7%, 18%),
);

$theme-map-default: (
  $--theme-primary: hsl(180, 53%, 34%),
  $--theme-background: hsl(0, 0%, 97%),
);

$theme-transition-duration: 1500ms;

// Vars
:root {
  --font-family: $font-family;
  --font-family-alt: $font-size-base;
  --font-weight: 500;

  /* colors */
  --theme-primary: hsl(180, 53%, 34%);
  --theme-secondary: hsl(40, 93%, 53%);
  --theme-background: hsl(0, 0%, 97%);
  --color-accent: hsl(0, 73%, 52%);
  --black: hsl(0, 0%, 0%);
  --white: hsl(0, 0%, 100%);
  --dark-grey: #1c1c1c;
  --light-grey: #f2f2f2;
  // hsl for opacity
  --theme-primary-hsl: 180, 53%, 34%;
  --theme-secondary-hsl: 40, 93%, 53%;
  --theme-background-hsl: 0, 0%, 97%;
  --color-accent-hsl: 0, 73%, 52%;
  --black-hsl: 0, 0%, 0%;
  --white-hsl: 0, 0%, 100%;

  /* grid */
  --grid-gap: 4vw;
  --form-col-gap: 7.5px;
  --max-width: calc(87.5rem + #{var(--grid-gap)} * 2); // 1400
  --max-width-s: calc(60rem + #{var(--grid-gap)} * 2); // 960
  --max-width-xs: calc(52.5rem + #{var(--grid-gap)} * 2); // 840
  --max-width-xxs: calc(40rem + #{var(--grid-gap)} * 2); // 640
  --max-width-md: calc(68.75rem + #{var(--grid-gap)} * 2); // 1100
  --max-width-l: calc(80rem + #{var(--grid-gap)} * 2); // 1280
  --max-width-xl: 102.5rem; // 1640
  --max-width-xxl: calc(115rem + #{var(--grid-gap)} * 2); // 1840
  --spacing-xx-small: .125rem; /* navbar */
  --navbar-height-large: 105px;
  --navbar-height-med: 80px;
  --navbar-height-small: 60px;
  --radius: 1.5rem;
}
