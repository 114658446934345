/**
 * Fluid Type - responsive font size base on window width
 *
 * Usage:
 * @include fluid-type(320px, 1400px, 14px, 60px);
 * @include fluid-type(min window width, max window width, min font size, max font size);
 *
 */
/// poly-fluid-sizing
/// Generate linear interpolated size values through multiple break points
/// @param $property - A string CSS property name
/// @param $map - A SASS map of viewport unit and size value pairs
/// @requires function linear-interpolation
/// @requires function map-sort
/// @example
///   @include poly-fluid-sizing('font-size', (576px: 22px, 768px: 24px, 992px: 34px));
/// @author Jake Wilson <jake.e.wilson@gmail.com>
@mixin poly-fluid-sizing($property, $map) {
  // Get the number of provided breakpoints
  $length: length(map-keys($map));

  // Error if the number of breakpoints is < 2
  @if ($length < 2) {
    @error "poly-fluid-sizing() $map requires at least values";
  }

  // Sort the map by viewport width (key)
  $map: map-sort($map);
  $keys: map-keys($map);

  // Minimum size
  #{$property}: map-get($map, nth($keys, 1));

  // Interpolated size through breakpoints
  @for $i from 1 through ($length - 1) {
    @media (min-width: nth($keys, $i)) {
      #{$property}:
        linear-interpolation(
          (
            nth($keys, $i): map-get($map, nth($keys, $i)),
            nth($keys, ($i + 1)): map-get($map, nth($keys, ($i + 1)))
          )
        );
    }
  }

  // Maxmimum size
  @media (min-width: nth($keys, $length)) {
    #{$property}: map-get($map, nth($keys, $length));
  }
}

@function strip-unit($value) {
  @return calc($value / ($value * 0 + 1rem));
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @include mappy-bp($min-vw) {
        font-size:
          calc(
            #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          );
      }
      @include mappy-bp($max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}
