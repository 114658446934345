// Pagination
.thom-search--pagination {
  display: block;
  font-size: 0;
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--form-col-gap);
    transition: opacity .2s ease-in-out;
    .page-item {
      cursor: pointer;
      font-size: 14px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      opacity: 0.6;
      position: relative;
      &.is-selected {
        font-weight: 700;
        opacity: 1;
        &::after{
            content: '';
            width: 100%;
            height: 1px;
            background-color: currentColor;
            position: absolute;
            bottom: 0;
            left: 0;
        }
      }

      .ico-next{
        &:after{
          display: block;
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");
          width: 16px;
          height: 16px;
        }
      }
      .ico-last{
        &:after{
          display: block;
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708'/%3E%3Cpath fill-rule='evenodd' d='M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");
          width: 16px;
          height: 16px;
        }
      }
      .ico-first{
        &:after{
          display: block;
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0'/%3E%3Cpath fill-rule='evenodd' d='M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0'/%3E%3C/svg%3E");
          width: 16px;
          height: 16px;
        }
      }
      .ico-prev{
        &:after{
          display: block;
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-chevron-left' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0'/%3E%3C/svg%3E");
          width: 16px;
          height: 16px;
        }
      }

      @media (hover: hover) {
        transition: $transition-base;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
