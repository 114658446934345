// App styles
.app--wrap {
  position: relative;
  isolation: isolate;
  background-color: var(--black);
  background-size: 200% 150%;
  background-position-x: 80%;
  background-position-y: 0;
  background-repeat: no-repeat;
  background-image: radial-gradient(circle, theme-var($--theme-primary) 0%, transparent 70%);
  color: var(--white);
}

// intro
.app--intro {
  padding-top: calc(var(--navbar-height-small) + 20px);
  position: relative;
  z-index: 1;
  @include mappy-bp(med) {
    padding-top: calc(var(--navbar-height-med) + 20px);
  }
  @include mappy-bp(large) {
    padding-top: calc(var(--navbar-height-large) + 20px);
  }
  .article:first-child {
    padding-top: 0;
  }
  .text {
    color: var(--white);
  }
  .inner__flex {
    & > *:last-child {
      padding-right: calc(var(--grid-gap));
      @include mappy-bp(med) {
        padding-right: calc(var(--grid-gap) / 2);
      }
    }
  }
  .filters{
    display: flex;
    .thom-dropdown{
      .thom-dropdown--label{
        &:after{
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
        }
      }
    }
  }
}

.bk--white__b-70 {
  position: relative;
  isolation: isolate;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 70%;
    width: 100%;
    background-color: var(--white);
    z-index: -1;
  }
}

// grecaptcha
.grecaptcha-badge{
  &.hide{
    visibility: hidden;
  }
}
