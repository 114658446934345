/*!
 * Project: Project Name
 * Author: Name
 * E-mail: E-mail
 * Website:
 */

/**
 * This is where all of the stylesheets are compiled.
 * They are processed in the order they are imported
 * to ensure the consistent increase of specificity.
 *
 *    ITCSS Methodology: Inverted Triangle CSS
 *
 *    **************   1. Settings
 *     ************    2. Tools
 *      **********     3. Generic
 *       ********      4. Elements
 *        ******       5. Objects
 *         ****        6. Components
 *          **         7. Utilities
 *
 * 1. Settings.........Global configuration and variables.
 * 2. Tools............Functions and mixins.
 * 3. Generic..........Ground zero styles.
 *                     (normalizing.css, box-sizing etc...)
 * 4. Elements.........Unclassed (bare) HTML element
 *                     (like H1, Ul, A etc...)
 * 5. Objects..........Common non-cosmetic structural design patterns.
 *                     e.g containers, rows, grids, colums etc...
 * 6. Components.......Specific cosmetic elements of UI.
 *                     e.g. buttons, forms, header, footer etc...
 * 7. Utilities........Helpers and overrides.
 *
 *    Modules..........Multi-part components.
 *                     e.g. Navbar (HTML, CSS and JS).
 *    Shame............All the CSS, hacks and things we are not proud of.
 */

@import '../node_modules/css-reset-and-normalize/scss/reset-and-normalize';
@import '../node_modules/css-reset-and-normalize/scss/button-reset';
@import '../node_modules/css-reset-and-normalize/scss/link-reset';
@import '../node_modules//malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar';
@import "settings/_animations.scss";
@import "settings/_barba-transitions.scss";
@import "settings/_global.scss";
@import "settings/_theme.scss";
@import "tools/_clearfix.scss";
@import "tools/_fluid-type.scss";
@import "tools/_list-unstyled.scss";
@import "tools/_max-width-center.scss";
@import "tools/_pseudo.scss";
@import "tools/_rem.scss";
@import "tools/_responsive-ratio.scss";
@import "tools/_row.scss";
@import "tools/_spread-map.scss";
@import "tools/_theme-var.scss";
@import "tools/_triangle.scss";
@import "generic/_box-sizing.scss";
@import "generic/_font-face.scss";
@import "generic/_print.scss";
@import "generic/_transitions.scss";
@import "objects/_breakpoints.scss";
@import "objects/_icons.scss";
@import "elements/_article.scss";
@import "elements/_banner.scss";
@import "elements/_breadcrumbs.scss";
@import "elements/_carousel.scss";
@import "elements/_document.scss";
@import "elements/_event-alert.scss";
@import "elements/_form.scss";
@import "elements/_layout.scss";
@import "elements/_lazy.scss";
@import "elements/_lightgallery-replace.scss";
@import "elements/_links.scss";
@import "elements/_lists.scss";
@import "elements/_main-form.scss";
@import "elements/_main-slider.scss";
@import "elements/_map.scss";
@import "elements/_media.scss";
@import "elements/_modal-replace.scss";
@import "elements/_paragraphs.scss";
@import "elements/_search.scss";
@import "elements/_social.scss";
@import "elements/_spinner.scss";
@import "elements/_tables.scss";
@import "elements/_tabs.scss";
@import "elements/_timeline.scss";
@import "elements/layouts/_app.scss";
@import "elements/layouts/_home-highlights.scss";
@import "elements/layouts/_jobs.scss";
@import "elements/layouts/_news.scss";
@import "elements/layouts/_product-dett.scss";
@import "elements/layouts/_products.scss";
@import "components/_buttons.scss";
@import "components/_footer.scss";
@import "components/_headings.scss";
@import "utilities/_block-alignment.scss";
@import "utilities/_clearfix.scss";
@import "utilities/_text-alignment.scss";
@import "utilities/_visibility.scss";
@import "/Volumes/Samsung_T5/Siti/dyamach/SVILUPPO/SITO/src/modules/dropdown/dropdown.scss";
@import "/Volumes/Samsung_T5/Siti/dyamach/SVILUPPO/SITO/src/modules/navbar/navbar.scss";
@import 'shame';
