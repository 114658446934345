// News

// intro
.news-intro {
    background-color: var(--black);
  background-size: 200% 150%;
  background-position-x: 80%;
  background-position-y: 0;
  background-repeat: no-repeat;
  background-image: radial-gradient(circle, theme-var($--theme-primary) 0%, transparent 70%);
  position: relative;
  isolation: isolate;
//   min-height: 20vh;
  padding-bottom: calc(var(--grid-gap) * 2);
  padding-top: calc(var(--navbar-height-small) + 20px);
  z-index: 1;
  @include mappy-bp(med) {
    padding-bottom: calc(var(--grid-gap));
    padding-top: calc(var(--navbar-height-med) + 20px);
  }
  @include mappy-bp(large) {
    padding-top: calc(var(--navbar-height-large) + 20px);
  }
  .article:first-child {
    padding-top: 0;
  }
  .text {
    color: var(--white);
  }
  .inner__flex {
    & > *:first-child {
      @include mappy-bp(med) {
        flex-basis: 55%;
      }
    }
    & > *:last-child {
      padding-right: calc(var(--grid-gap));
      @include mappy-bp(med) {
        padding-right: calc(var(--grid-gap) / 2);
        flex-basis: 45%;
      }
    }
  }

  .filters {
    // display: flex;
    transform: translateY(50%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    .thom-dropdown{
        @include mappy-bp(med) {
            display: inline-table;
        }
    }
    
  }
}

// Lista news
.news-list {
  // background-color: var(--white);
  .content {
    // padding-bottom: calc(var(--grid-gap) * 2);
    // padding-top: calc(var(--grid-gap) * 2 + 35px);
    // @include mappy-bp(med) {
    //   padding-bottom: calc(var(--grid-gap));
    //   padding-top: calc(var(--grid-gap));
    // }
    // @include mappy-bp(large) {
    //   padding-top: calc(var(--grid-gap));
    // }
    ul {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 50px;
      @include mappy-bp(med) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include mappy-bp(large) {
        grid-template-columns: repeat(3, 1fr);
      }
      .item {
        margin-bottom: 30px;
        @include mappy-bp(med) {
          margin-bottom: 35px;
          margin-top: 35px;
        }
        @include mappy-bp(large) {
          margin-bottom: 40px;
          margin-top: 40px;
        }
        a {
          display: block;
        }
        .image {
          @include responsive-ratio(4, 3);
          position: relative;
          margin-bottom: 30px;
          .inner {
            justify-content: center;
            align-items: center;
            height: 100%;
            overflow: hidden;
            img{
                object-fit: cover;
                height: 100%;
                @media (hover: hover) {
                    transition: $transition-transform;
                }
            }            
          }
        }
        @media (hover: hover) {
            .text{
                transition: $transition-base;
            }
          &:hover {
            .text {
                opacity: .8;
            }
            img {
                transform: scale(1.05);
              }
          }
        }
      }
    }
  }
}

// Detail
.news--dett {
    position: relative;
    isolation: isolate;
    // color: var(--black);
    // background-color: var(--white);
    // padding-bottom: 80px;
    padding-bottom: calc(var(--grid-gap) * 2);
    // padding-top: calc(var(--grid-gap) * 2);
    @include mappy-bp(med) {
        padding-bottom: calc(var(--grid-gap));
        // padding-top: calc(var(--grid-gap));
    }
    .article {
        padding-bottom: 0;
        padding-top: 0;
        margin-bottom: 30px;
        // background-color: var(--white);
        .media{ 
            & > div{
                display: inline-block;
            }
            img{
                width: auto;
            }
            a.btn-gallery{
              background-color: var(--black);
              position: absolute;
              right: 15px;
              top: 15px;
              z-index: 10;
              transform: scale(.8);
              transform-origin: 100% 0%;
              span{
                color: var(--white);
              }
              @media (hover: hover) {
                &:hover{
                    background-color: var(--theme-primary);
                    span{
                        filter: invert(0);
                    }
                }
              }
            }
        }
    }

    ol,
    ul{
      // @include fluid-type(20rem, 87.5rem, $font-size-base * 1, $font-size-base * 1.125);
      li{
        padding-left: 1rem;
        &:not(:last-child){
          margin-bottom: 4px;
        }
        &:before{
          content: '\2022';
          margin-left: -1rem;
          margin-right: 8px;
        }
      }
    }
  }
