img[data-src],
img[data-srcset] {
  display: block;
  // min-height: 1px;
}

img.lazy,
img.js-toa-lazy,
.lazyvideo{
  filter: blur(.3rem);
  transition: filter .3s linear;
}

img.loaded,
.lazyvideo.loaded{
  filter: blur(0);
}
img:not([src]) {
  visibility: hidden;
}
