/**
 * Spread Map
 *
 * include a map in a class
 * Usage:
 * @include spread-map(map-name);
 */

@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}
