/**
 * Document defaults (html, body)
 */

html,
body{
  font-family: $font-family;
  line-height: $line-height-base;
}

html {
  // background-color: $body-color;
  background-color: var(--white);
  font-size: $font-size-base;
  font-weight: var(--font-weight);
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
