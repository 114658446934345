/**
 * Thom Dropdown
 */

.thom-dropdown {
  display: block;
  overflow: hidden;
  position: relative;
  text-align: left;
  min-width: 260px;
  z-index: 0;
  // --height: 45px;
  // --height: 56px;
  &--label {
    --height: 45px;
    background-color: theme-var($--theme-primary);
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: var(--height);
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    cursor: pointer;
    @include mappy-bp(med) {
      --height: 56px;
      font-size: 18px;
    }
    &:after{
      content: " ";
      background-image: url($ico-chevron-down);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin-left: 40px;
    }
    span{
      height: 24px;
      width: 24px;
      margin-left: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.is-open {
    overflow: visible;
    z-index: 50;
    .thom-dropdown--label {
      // background: theme-var($--theme-primary);
    }
    .thom-dropdown--options {
      visibility: visible;
      opacity: 1;
    }
  }
  &--options {
    background: theme-var($--theme-primary);
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    visibility: hidden;
    z-index: 10;
    opacity: 0;
    // transform: translateX(-50%);
    box-shadow: 0 0 20px rgba(var(--black), .5);
    border-top: 1px solid hsl(var(--white-hsl), .2);
    &-item {
      --height: 45px;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: var(--height);
      transition: $transition-base;
      opacity: 0.7;
      margin: 0 !important;
      background-color: transparent !important;
      max-width: none !important;
      text-align: left !important;
      justify-content: flex-start !important;
      padding: 0 20px;
      &::after{
        display: none;
      }
      @include mappy-bp(med) {
        --height: 56px;
        font-size: 18px;
      }
      &:not(:last-child){
        border-bottom: 1px solid hsl(var(--white-hsl), .2);
      }
      &:hover:not(.is-active):not(.is-selected) {
        opacity: 1;
      }
      &.selected,
      &.is-selected {
        pointer-events: none;
        cursor: default;
        display: none !important;
      }
    }
  }

  .mCSB_scrollTools{
    width: 24px;
  }
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: var(--theme-secondary);
  }
  .mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent;
  }
  .mCSB_inside > .mCSB_container {
    margin-right: 34px;
  }
}
