/**
 * Jobs
 */

.jobs{
  padding-bottom: var(--grid-gap);
  &--list{

  }
  &--item{
    // background-color: $color-primary;
    border-bottom: 1px solid var(--theme-primary);
    // color: $white;
    // margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    $item: &;
    @include mappy-bp(med) {
      max-width: 900px;
      padding-left: 30px;
      padding-right: 30px;
    }
    &--title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      gap: 30px;
      padding-top: 15px;
      padding-bottom: 15px;
      @include fluid-type(20rem, 87.5rem, $font-size-base * 1.375, $font-size-base * 2);
      @include mappy-bp(med) {
        padding-top: 25px;
        padding-bottom: 25px;
      }
      span{
        margin-bottom: 0;
      }
      .ico{
        width: 25px;
        svg{
          width: 100%;
        }
      }
    }

    &--content{
      display: none;
      padding-top: 20px;
      padding-bottom: 20px;
      @include mappy-bp(med) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
      .text{
        margin-bottom: 80px;
        @include fluid-type(20rem, 87.5rem, $font-size-base * 1, $font-size-base * 1.125);
        & > *:not(:last-child){
          margin-bottom: 20px;
        }
        p{
          @include fluid-type(20rem, 87.5rem, $font-size-base * 1, $font-size-base * 1.125);
        }
        ol,
        ul{
          @include fluid-type(20rem, 87.5rem, $font-size-base * 1, $font-size-base * 1.125);
          li{
            padding-left: 1rem;
            &:not(:last-child){
              margin-bottom: 4px;
            }
            &:before{
              content: '\2022';
              margin-left: -1rem;
              margin-right: 8px;
            }
          }
        }
      }
      .thom-form{
        &__input-textarea{
          min-height: initial;
          height: 100px;
          overflow: hidden;
        }
        &__submit{
          background-color: var(--black);
          color: var(--white);
        }
        &__select{
          background-color: rgba(var(--theme-primary), 0);
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");
          option{
            background: var(--theme-primary);
          }
        }
      }
    }
    // &:not(.free-application) {
    //   .thom-form{
    //     .thom-form__checkcont__customcheckbox{
    //       border-color: var(--theme-secondary);
    //       &:after{
    //         background-color: var(--theme-secondary);
    //       }
    //     }
    //     .thom-form__checkcont__label{
    //       color: var(--theme-secondary);
    //       a{
    //         color: var(--theme-secondary);
    //       }
    //     }
    //     .thom-form-selectfile{
    //       .thom-form-selectfile__label{
    //         border-color: var(--white);
    //         color: var(--white);
    //       }
    //       .thom-form-selectfile__fakeBtn{
    //         background-color: var(--white);
    //         color: var(--theme-secondary);
    //       }
    //     }

    //     .thom-form__checkcont__checkbox.input-validation-error ~ .thom-form__checkcont__label,
    //     .thom-form__checkcont__checkbox.input-validation-error ~ .thom-form__checkcont__label a {
    //       color: var(--color-accent);
    //     }
    //     .thom-form__checkcont__checkbox.input-validation-error ~ .thom-form__checkcont__customcheckbox {
    //       border-color: var(--color-accent);
    //     }
    //   }
    // }

    &.free-application{
      background-color: var(--theme-primary);
      // border-color: $color-primary;
      // color: var(--theme-primary);
      margin-top: calc(var(--grid-gap) + 40px);
      @include mappy-bp(med) {
        margin-top: calc(var(--grid-gap) + 20px);
      }
      .jobs--item--title{
        .ico{
          filter: invert(0);
        }
      }
    }

    &:only-child{
      margin-top: 0 !important;
    }

    &.open{
      #{$item}--title{
        .ico svg{
          transform: rotate(135deg);
        }
      }
    }
  }
}

#formJob{
  display: none;
}
