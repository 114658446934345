@import "../tools/spread-map";

// Theme
:root[data-theme="dental"] {
  @include spread-map($theme-map-dental);
}

:root[data-theme="goldsmith"] {
  @include spread-map($theme-map-goldsmith);
}

:root[data-theme="default"] {
  @include spread-map($theme-map-default);
}

:root.theme-transition,
:root.theme-transition * {
  transition: background-color $theme-transition-duration !important;
  transition-delay: 0s !important;
}
