.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(var(--grid-gap) / 4);
  @include mappy-bp(med) {
    // display: flex;
    // flex-wrap: wrap;
  }
  &-item {
    font-size: 15px;
    color: var(--white);
    &:after {
      content: "\002F";
      margin: 0 2px;
    }
    &:last-child {
      span{
        color: theme-var($--theme-primary) !important;
      }
      &:after {
        display: none;
      }
    }
  }
}
