// Tabs
.tabs {
  display: flex;
  flex-wrap: wrap;

  --height: 58px;
  @include mappy-bp(med) {
    --height: 78px;
  }
  label {
    order: 1;
    align-items: center;
    cursor: pointer;
    display: flex;
    height: var(--height);
    justify-content: space-between;
    background-color: hsl(var(--black-hsl), .02);
    @include fluid-type(
      20rem,
      87.5rem,
      $font-size-base * 1.25,
      $font-size-base * 1.5
    );
    padding: 0 calc(var(--grid-gap));
    transition: $transition-base;
    &::after {
      content: "";
      background-image: url($ico-plus);
      background-size: cover;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      transition: $transition-base;
      margin-left: 15px;
    }
    @include mappy-bp(med) {
      min-width: 270px;
      justify-content: center;
      text-align: center;
      &::after {
        display: none;
      }
    }
    @media (hover: hover) {
      &:hover {
        background-color: hsl(var(--black-hsl), .035);
      }
    }
  }

  .tab {
    order: 99; // Put the tabs last
    flex-grow: 1;

    visibility: hidden;
    opacity: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
    width: 1px;
    position: absolute;

    background-color: hsl(var(--black-hsl), .05);
    transition: opacity .65s linear, all .8s .8s;
  }

  input[type="radio"] {
    display: none;
    &:checked + label {
      background-color: hsl(var(--black-hsl), .05);
      pointer-events: none;

      @include mappy-bp(small med) {
        &::after {
          transform: rotate(225deg);
        }
      }

      & + .tab {
        visibility: visible;
        opacity: 1;
        clip: auto;
        width: 100%;
        position: relative;
        transition: visibility 0s, opacity .65s linear;
      }
    }
  }

  @include mappy-bp(small med) {
    .tab,
    label {
      order: initial;
    }
    label {
      width: 100%;
    }
  }
}
