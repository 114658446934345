/**
 * Spinner
 * https://tobiasahlin.com/spinkit/
 */

// spinner circle
$circle-size: 90px;
$circle-stroke-color: var(--theme-primary);
$circle-radius: 45;

@function get-dash-value($radius, $percentage) {
  // Using $radius, calculate circumference.
  $circumference: 2 * 3.1415927 * $radius;
  // Convert percentage to decimal.
  // i.e. 50% = .5.
  $percentage-as-decimal: calc($percentage / 100%);
  // Return unit value.
  @return $circumference * $percentage-as-decimal;
}

.spinner-circle {
  height: $circle-size;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $circle-size;
}

.circle__svg {
  animation: 2s linear infinite both circle__svg;
}

.page-loader {
  background-color: hsl(var(--theme-background-hsl), .3);
  backdrop-filter: blur(10px);
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
  pointer-events: none;
  transition: $transition-base;
  .circle__svg {
    animation: none;
  }

  &.show {
    opacity: 1;
    pointer-events: all;
    z-index: 1250;

    .circle__svg {
      animation: 2s linear infinite both circle__svg;
    }
  }
}

@keyframes circle__svg {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.circle__svg-circle {
  animation: 1.4s ease-in-out infinite both circle__svg-circle;
  fill: transparent;
  stroke: $circle-stroke-color;
  stroke-dasharray: get-dash-value($circle-radius, 100%);
  stroke-linecap: round;
  stroke-width: 2;
  transform-origin: 50% 50%;
}

@keyframes circle__svg-circle {
  0%,
  25% {
    stroke-dashoffset: get-dash-value($circle-radius, 97%);
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: get-dash-value($circle-radius, 25%);
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: get-dash-value($circle-radius, 97%);
    transform: rotate(360deg);
  }
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: var(--white);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -.32s;
  animation-delay: -.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -.16s;
  animation-delay: -.16s;
}

.spinner:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid var(--white);
  border-color: var(--white) transparent var(--white) transparent;
  animation: dual-ring .8s linear infinite;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
