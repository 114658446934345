.carousel {
  margin: 0 calc(-1 * (var(--grid-gap) / 4));
  @include mappy-bp(med) {
  }
  .item {
    width: 100%;
    padding: 0 calc(var(--grid-gap) / 4);
    @include mappy-bp(med) {
      width: 50%;
    }
    @include mappy-bp(large) {
      width: calc(100% / 3);
    }
    .image,
    &--media {
      margin-bottom: 30px;
      overflow: hidden;
    }
    &--media{
      isolation: isolate;
      & > div{
        position: relative;
      }
      .image{
        overflow: hidden;
        position: relative;
        transition: $transition-base;
        z-index: 10;
        margin-bottom: 0;
      }
      .video{
        overflow: hidden;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      .video--item,
      iframe,
      object,
      embed {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: none !important;
      }
      &.is-playing{
        .image{
          opacity: 0;
          pointer-events: none;
        }
      }
      .label{
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        padding: 0 calc(var(--grid-gap));
        color: var(--white);
      }
    }
    &[href] {
      @media (hover: hover) {
        img {
          transition: $transition-transform;
        }
        &:hover {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  .flickity-page-dots {
    display: none;
    position: relative;
    bottom: auto;
    padding: 20px;
    .dot {
      opacity: .7;
      &.is-selected {
        background-color: theme-var($--theme-primary);
      }
      @media (hover: hover) {
        transition: $transition-base;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
