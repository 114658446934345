/**
 * Visibility
 */

.is-hidden {
  display: none !important;
}
.is-visible {
  display: block !important;
}

// Hide content accessibly
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto; /* new - was 1px */
  margin: 0; /* new - was -1px */
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

.hide-nothover{
  @media (hover: hover) and (min-width: 640px) {
    border: 0;
    clip: rect(0 0 0 0);
    height: auto; /* new - was 1px */
    margin: 0; /* new - was -1px */
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap; /* 1 */
    pointer-events: none;
  }
}

.hide-s{
  @include mappy-bp(small med) {
    display: none;
  }
}

.hide-m-down{
  @include mappy-bp(small large) {
    display: none;
  }
}

.hide-large{
  @include mappy-bp(large) {
    display: none;
  }
}

.show-touch{
  display: none !important;
  @media (hover: none){
    display: flex !important;
  }
}

.hide-touch{
  display: flex !important;
  @media (hover: none){
    display: none !important;
  }
}
