/**
 * Modal
 */

@import "../node_modules/jquery-modal/jquery.modal.min";
@import "../node_modules/simplebar/dist/simplebar.min";

.jquery-modal {
  &.blocker {
    background-color: var(--white);
    z-index: 2000;
    padding: 0;
    @include mappy-bp(max med) {
      padding: 0;
    }
  }

  .modal,
  .ajax-load {
    background-color: var(--white);
    border-radius: 0;
    color: var(--black);
    padding: 20px;
    padding-top: calc(120px);
    text-align: left;
    @include mappy-bp(med) {
      padding: calc(var(--grid-gap) * 1);
      padding-right: 10px;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    @include mappy-bp(large) {
      padding-right: calc(var(--grid-gap) * 1);
    }
    .scroller {
      display: block;
      max-width: 800px;
      height: 100%;
      overflow: auto;
      padding-right: 40px;
      margin: 0 auto;
    }
    h2 {
      margin-bottom: 40px;
    }
    .close {
      position: fixed;
      top: 20px;
      left: 50%;
      //  background: hsl(var(--black-hsl), .5);
      backdrop-filter: blur(30px);
      transform: translateX(-50%);
      z-index: 10;
      @include mappy-bp(med) {
        left: auto;
        right: 40px;
        transform: translateX(0);
      }
      @include mappy-bp(large) {
        top: calc(var(--grid-gap) / 2);
        right: calc(var(--grid-gap) / 2);
      }
    }
  }
  .ajax-load {
    padding-bottom: 0;
    .simplebar-content > *:last-child {
      padding-bottom: 40px;
    }
  }
}

.dialog{
  .modal-callback{
    padding: 20px;
    padding-top: 100px;
    max-width: 500px;
    @include mappy-bp(med) {
      padding-left: 40px;
      padding-bottom: 40px;
      padding-right: 80px;
    }
    .close {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      @include mappy-bp(med) {
        left: auto;
        right: 10px;
        transform: translateX(0);
      }
    }
    h2 {
      margin-bottom: 40px;
    }
  }
}
