// Products

// intro
.product-intro {
  position: relative;
  isolation: isolate;
  min-height: 20vh;
  padding-bottom: calc(var(--grid-gap) * 2);
  padding-top: calc(var(--navbar-height-small) + 20px);
  @include mappy-bp(med) {
    padding-bottom: calc(var(--grid-gap));
    padding-top: calc(var(--navbar-height-med) + 20px);
  }
  @include mappy-bp(large) {
    padding-top: calc(var(--navbar-height-large) + 20px);
  }
  .article:first-child {
    padding-top: 0;
  }
  .media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    & > div {
      height: 100%;
    }
    .image{
      height: 100%;
      img{
        object-fit: cover;
        min-height: 100%;
        max-width: none;
        min-width: 100%;
      }
    }
  }
  .text {
    color: var(--white);
  }
  .inner__flex {
    & > *:first-child {
      @include mappy-bp(med) {
        flex-basis: 55%;
      }
    }
    & > *:last-child {
      padding-right: calc(var(--grid-gap));
      @include mappy-bp(med) {
        padding-right: calc(var(--grid-gap) / 2);
        flex-basis: 45%;
      }
    }
  }
}

// Lista prodotti
.products-list {
  background-color: var(--white);
  .filters {
    // display: flex;
    transform: translateY(-50%);
    position: relative;
    z-index: 1;
    .filter {
      width: 100%;
      max-width: 240px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 18px;
      background-color: var(--white);
      isolation: isolate;
      position: relative;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 25px;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background-color: var(--black);
      }
      @for $i from 1 through 50 {
        &:nth-child(#{$i}) {
          &:after {
            opacity: calc(1 - calc((#{$i} / 10) * 2.5));
            transition: background-color .3s linear;
          }
        }
      }
      &.selected {
        &::after {
          background-color: var(--theme-primary);
          opacity: 1;
        }
      }
      @media (hover: hover) {
        &:not(.selected):hover {
          &:after {
            background-color: var(--theme-primary);
            // opacity: .1;
          }
        }
      }
    }
    &-desk {
      display: none;
      @include mappy-bp(large) {
        display: flex;
      }
    }
    &-mob {
      display: block;
      @include mappy-bp(large) {
        display: none;
      }
    }
  }
  .content {
    padding-bottom: calc(var(--grid-gap) * 2);
    padding-top: calc(var(--grid-gap) * 2);
    @include mappy-bp(med) {
      padding-bottom: calc(var(--grid-gap));
      padding-top: calc(var(--grid-gap) - 35px);
    }
    @include mappy-bp(large) {
      padding-top: calc(var(--grid-gap) - 50px);
    }
    ul {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 50px;
      @include mappy-bp(med) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include mappy-bp(large) {
        grid-template-columns: repeat(3, 1fr);
      }
      .item {
        margin-bottom: 30px;
        @include mappy-bp(med) {
          margin-bottom: 35px;
          margin-top: 35px;
        }
        @include mappy-bp(large) {
          margin-bottom: 50px;
          margin-top: 50px;
        }
        a {
          display: block;
        }
        .image {
          @include responsive-ratio(4, 5);
          position: relative;
          background-color: var(--light-grey);
          background-image: linear-gradient(0deg, theme-var($--theme-primary), var(--light-grey) 100%);
          background-position: 0% -40%;
          background-repeat: no-repeat;
          background-size: 100% 200%;
          transition: $transition-base;
          margin-bottom: 30px;
          .inner {
            justify-content: center;
            align-items: center;
          }
        }
        @media (hover: hover) {
          &:hover {
            .image {
              // background-image: linear-gradient(0deg, theme-var($--theme-primary), var(--light-grey) 120%);
              background-size: 100% 100%;
              background-position: 0% 0%;
            }
          }
        }
      }
    }
  }
}

// Highlights
.products-highlights {
  display: block;
  background-color: var(--black);
  background-size: 200% 100%;
  background-position-x: 80%;
  background-position-y: center;
  background-repeat: no-repeat;
  background-image: radial-gradient(circle, theme-var($--theme-primary) 0%, transparent 70%);
  padding-bottom: calc(var(--grid-gap) * 2);
  @include mappy-bp(med) {
    padding-bottom: calc(var(--grid-gap));
  }
  *:not(.btn, .btn span, .btn strong) {
    color: var(--white);
  }
  & > *:first-child {
    isolation: isolate;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--white);
      width: 100%;
      height: 35%;
      z-index: -1;
      @include mappy-bp(med) {
        height: 50%;
      }
    }
  }
  .article {
    h3 {
      margin-bottom: 20px;
    }
    .inner__flex {
      align-items: center;
      .text {
        padding-right: calc(var(--grid-gap) * 2);
      }
    }
  }
}
