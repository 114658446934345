// Banner
.banner {
  .title {
    margin: 0;
    @include fluid-type(
      20rem,
      87.5rem,
      $font-size-base * 1.5,
      $font-size-base * 3.375
    ); // 24 - 60
  }
  .media {
    @media (hover: hover) {
      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }
    & > div {
      overflow: hidden;
    }
  }
  .image {
    @media (hover: hover) {
      img {
        transition: $transition-transform;
      }
    }
  }
}
