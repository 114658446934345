/**
* Replace for Lightgallery plugin
*/

@import '../node_modules/lightgallery/scss/lightgallery.scss';
@import '../node_modules/lightgallery/scss/lg-transitions.scss';
@import '../node_modules/lightgallery/scss/lg-zoom.scss';
@import '../node_modules/lightgallery/scss/lg-video.scss';

.lg-toolbar{
  background-color: transparent;
  left: 0;
  top: 10px;
  padding: 0 10px;
  width: 100%;
  text-align: center;
}
.lg-backdrop{
  background-color: rgba(0,0,0, .9);
  z-index: 1500;
}
.lg-outer{
  z-index: 1501;
}
