/**
 * Social
 */

.social {
  color: var(--theme-primary);
  display: block;
  &--list {
    align-items: center;
    display: flex;
    a:not(:last-child) {
      margin-right: 20px;
      @include mappy-bp(med) {
        margin-right: 20px;
      }
      @include mappy-bp(large) {
        margin-right: 30px;
      }
    }
    .item {
      fill: var(--theme-primary);
      height: 30px;
      width: 30px;
      @include mappy-bp(med) {
        height: 30px;
        width: 30px;
      }
      @include mappy-bp(large) {
        height: 42px;
        width: 42px;
      }
    }
    a {
      @include mappy-bp(med) {
        img {
          transition: $transition-base;
        }
        &:hover img {
          opacity: .8;
        }
      }
    }
  }
}
.social--content{
  display: flex;
  justify-content: center;
  margin-top: calc(var(--grid-gap));
  .item{
    @include mappy-bp(large) {
      height: 35px;
      width: 35px;
    }
  }
}
