/**
 * Links
 */

$link-decoration: none !default;

a {
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
  @media (hover: hover) {
    transition: $transition-base;
  }
}


.link{
  cursor: pointer;
  @media (hover: hover) {
    transition: $transition-base;
    &:hover {
      opacity: .7;
    }
  }
}

.underline {
  width: calc(100%);
  background-image: linear-gradient(transparent calc(100% - 10px), currentColor 10px);
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 0% 2px;
  transition: background-size .2s;
  &-reverse{
    background-size: 100% 2px;
  }
}

*:hover > .underline,
a:hover .underline {
  background-size: 100% 2px;
  &-reverse{
    background-size: 0% 2px;
  }
}
