/**
 * Footer
 */

.footer {
  background-color: var(--dark-grey);
  color: var(--white);
  position: relative;
  overflow: hidden;
  isolation: isolate;
  padding-top: calc(var(--grid-gap) * 2);
  $footer: &;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: linear-gradient(20deg, theme-var($--theme-primary) 0%, transparent 46%, transparent 100%);
  }
  @include mappy-bp(med) {
    padding-top: calc(var(--grid-gap) * 2);
  }
  a {
    color: var(--white);
    @media (hover: hover) {
      &:hover{
        opacity: .8;
      }
    }
  }

  .top {
    display: block;
    border-bottom: 1px solid hsl(var(--white-hsl), .5);
    padding-bottom: calc(var(--grid-gap) * 2);
    @include mappy-bp(med) {
      padding-left: 25px;
      padding-right: 25px;
    }
    @include mappy-bp(large) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: calc(var(--grid-gap));
      & > div {
        margin-right: calc(var(--grid-gap));
      }
    }
    @include mappy-bp(small large) {
      & > div:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
  .bottom {
    padding: 20px 0;
    & > * {
      display: block;
    }
    @include mappy-bp(small med) {
      & > *:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    @include mappy-bp(med) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 0;
      font-size: 18px;
    }
  }
  .links{
    display: flex;
    & > *:not(:last-child){
      margin-right: 15px;
    }
  }
  .brand {
    img,
    svg {
      display: block;
      width: auto;
      height: calc(var(--navbar-height-small) / 2.5);
      @include mappy-bp(med) {
        height: calc(var(--navbar-height-med) / 2.8);
      }
      @include mappy-bp(large) {
        height: calc(var(--navbar-height-large) / 2.8);
      }
    }
  }
  .info {
    display: block;
    .item {
      display: flex;
      align-items: center;
      font-size: 14px;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      @include mappy-bp(med) {
        font-size: 18px;
      }
      @include mappy-bp(large) {
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
      .ico {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        width: 30px;
        height: 30px;
        img,
        svg {
          width: 100%;
          height: 100%;
          max-width: 25px;
          max-height: 20px;
        }
        @include mappy-bp(med) {
          width: 40px;
          height: 40px;
          img,
          svg {
            max-width: 35px;
            max-height: 30px;
          }
        }
      }
    }
    .social{
      a:not(:last-child){
        margin-right: 15px;
      }
    }
  }
  .menu {
    display: block;
    a {
      display: block;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
      &.selected{
        text-decoration: underline;
      }
    }
    .lang{
      margin-top: 20px;
      a{
        font-size: 13px;
        margin-bottom: 2px;
        &.selected{
          pointer-events: none;
        }
      }
    }
  }
}
