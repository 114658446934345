/**
 * Navbar
 */

$navbar: ();

@function navbar($key) {
  @return map-get($navbar, $key);
}

.navbar {
  background-color: transparent;
  position: relative;
  z-index: 1200;
  color: var(--white);
  isolation: isolate;
  $navbar: &;
  &.tween {
    .navbar__wrapper{
      opacity: 1;
    }
  }
  .container{
    position: relative;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--navbar-height-small);
    flex: 0 0 100%;
    position: relative;
    opacity: 0;
    transition: $transition-base;
    isolation: isolate;
    @include mappy-bp(med) {
      height: var(--navbar-height-med);
    }
    @include mappy-bp(large) {
      height: var(--navbar-height-large);
    }
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--black);
      z-index: -1;
      opacity: 0;
      transition: all .3s linear;
      @include mappy-bp(med) {
        background-color: var(--white);
      }
    }
    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: calc(100% - 40px);
      height: 1px;
      background-color: hsl(var(--white-hsl), .2);
      transform: translateX(-50%);
      z-index: 0;
      @include mappy-bp(med) {
        width: calc(100% - (var(--grid-gap) * 2));
      }
    }
  }
  &__brand {
    align-items: center;
    display: flex;
    position: relative;
    height: 100%;
    isolation: isolate;
    img,
    svg{
      display: block;
      width: auto;
      height: calc(var(--navbar-height-small) / 2.5);
      @include mappy-bp(med) {
        height: calc(var(--navbar-height-med) / 2.8);
      }
      @include mappy-bp(large) {
        height: calc(var(--navbar-height-large) / 2.8);
      }
    }
    &.selected::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--white);
    }
    .brand_img{
      display: none;
      &.white{
        display: block;
      }
    }
  }

  

  &__dx,
  &__sx{
    align-items: center;
    display: flex;
    height: 100%;
    .submenu{
      --mGap: 8px;
      @include mappy-bp(med) {
        --mGap: 10px;
      }
      @include mappy-bp(wide) {
        --mGap: 12px;
      }
      background-color: var(--white);
      color: var(--black);
      position: absolute;
      left: 50%;
      // right: 0;
      // margin: 0 auto;
      top: 100%;
      transform: translateX(-50%) translateY(calc(-1 * var(--mGap)));
      // margin-left: calc(-1 * (var(--mGap) * 2));
      // margin-right: calc(-1 * (var(--mGap) * 2));
      z-index: -1;
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s linear;
      isolation: isolate;
      &:before{
        content: '';
        position: absolute;
        top: calc(-1 * (var(--mGap) * 2));
        left: calc(50% - var(--mGap));
        border-top: var(--mGap) solid transparent;
        border-bottom: var(--mGap) solid var(--white);
        border-left: var(--mGap) solid transparent;
        border-right: var(--mGap) solid transparent;
      }
      &:after{
        content: '';
        position: absolute;
        top: -100%;
        left: 0;
        background-color: transparent;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      a{
        background-color: var(--white);
        display: flex;
        align-items: center;
        height: 60px;
        padding: 0 calc(var(--mGap) * 2);
        white-space: nowrap;
        &:not(:last-child){
          border-bottom: 2px solid hsl(var(--black-hsl), .15);
        }
        &.selected{
          color: theme-var($--theme-primary);
        }
        @media (hover: hover) {
          &:hover{
            background-color: hsl(var(--black-hsl), .05);
          }
        }
      }
      &.show{
        opacity: 100;
        pointer-events: all;
        z-index: 0;
      }
    }
  }

  &__lang{
    --mGap: 8px;
    @include mappy-bp(med) {
      --mGap: 10px;
    }
    @include mappy-bp(wide) {
      --mGap: 12px;
    }
    display: none;
    align-items: center;
    margin-left: calc(var(--mGap) * 2);
    position: relative;
    height: 100%;
    @media (hover: hover) and (min-width: 640px) {
      display: flex;
    }
    &:before{
      content: '';
      width: 1px;
      background-color: currentColor;
      opacity: .2;
      height: calc(var(--navbar-height-small) / 2.5 + (var(--mGap)));
      @include mappy-bp(med) {
        height: calc(var(--navbar-height-med) / 2.8 + var(--mGap));
      }
      @include mappy-bp(large) {
        height: calc(var(--navbar-height-large) / 2.8 + var(--mGap));
      }
    }
    .label{
      font-size: 12px;
      text-transform: uppercase;
      padding: 0 calc(var(--mGap) * 2);
      position: relative;
      cursor: pointer;
    }
    .submenu{
      a{
        height: 40px;
        padding: 0 var(--mGap);
        font-size: 13px;
        text-align: center;
        justify-content: center;
      }
    }
  }

  &__menu-preview{
    display: none;
    height: 100%;
    @include mappy-bp(wide) {
      display: block;
    }
    ul{
      height: 100%;
      display: flex;
      li{
        --mGap: 8px;
        @include mappy-bp(med) {
          --mGap: 10px;
        }
        @include mappy-bp(wide) {
          --mGap: 12px;
        }
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;
        margin: 0 var(--mGap);
        isolation: isolate;
        &:last-child{
          margin-right: 0;
        }
        &.selected::after{
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: var(--white);
          z-index: -1;
        }
        @media (hover: hover) {
          a{
            transition: opacity .3s linear;
          }
          a:not(.selected):hover{
            opacity: .8;
          }
        }
      }
    }
  }
  
  &__toggler {
    border-radius: 0;
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;
    color: var(--white);
    transition: $transition-base;
    @include mappy-bp(med) {
      width: 50px;
      height: 50px;
    }
    @include mappy-bp(wide) {
      display: none;
    }
    &:focus {
      outline: none;
    }
    &:hover{
      opacity: .7;
    }
  }
  &__toggler-line {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: currentColor;
    height: 2px;
    width: calc(100% - 20px);
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
    @include mappy-bp(med) {
      height: 2px;
      width: calc(100% - 25px);
    }
    &:before,
    &:after{
      content: '';
      background-color: currentColor;
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
      @include mappy-bp(med) {
        height: 2px;
      }
    }
    &:before {
      top: -6px;
      @include mappy-bp(med) {
        top: -8px;
      }
    }
    &:after {
      top: 6px;
      @include mappy-bp(med) {
        top: 8px;
        transition: width .35s cubic-bezier(.455, .03, .515, .955);
      }
    }  
  }

  &__collapse{
    background: var(--black);
    opacity: 0;
    visibility: hidden;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    margin: 0 auto;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    width: 100vw;
    pointer-events: auto;
    padding-left: 0;
    padding-right: 0;
    z-index: -2;
    @include mappy-bp(med) {
      background-color: var(--white);
    }
    &--inner{
      display: block;
      height: auto;
      margin: 0 auto;
      min-height: 100%;
      padding-top: var(--navbar-height-small);
      width: 100%;
      @include mappy-bp(med) {
        padding-top: var(--navbar-height-med);
      }
      @include mappy-bp(large) {
        padding-top: var(--navbar-height-large);
      }
    }
    a.selected{
      pointer-events: none;
    }
    @include mappy-bp(large) {
      a span{
        transition: $transition-base;
      }
      a:not(.selected):hover span{
        opacity: .6;
      }
    }
    &__lang{
      border-top: 1px solid hsl(var(--white-hsl), .15);
      line-height: 1.6;
      padding: 20px 0;
      @include mappy-bp(med) {
        border-color: hsl(var(--black-hsl), .15);
      }
      a{
        strong{
          opacity: .8;
        }
        &:not(:last-child):after {
          content: "\002F";
          margin: 0 2px;
          opacity: .8;
        }
        &.selected {
          strong{
            text-decoration: underline;
            opacity: 1;
          }
        }
      }
    }
  }

  &__menu {    
    display: block;
    position: relative;
  }

  .menu-item {
    line-height: 1;
    position: relative;
    padding: 20px 0;
    @include mappy-bp(med) {
      color: var(--black);
    }
    a {
      display: flex;
      align-items: center;
      @include fluid-type(20rem, 87.5rem, $font-size-base * 1.5, $font-size-base * 2.75);
      opacity: .8;
      &.has-submenu{
        pointer-events: none;
        span{
          margin-right: 15px;
        }
        &:after{
          content: '\003E';
          transform: rotate(90deg);
          @include fluid-type(20rem, 87.5rem, $font-size-base * 1.15, $font-size-base * 2);
        }
      }
      &:hover{
        opacity: .95;
      }
    }

    &.selected{
      color: var(--theme-primary);
      a{
        opacity: 1;
      }
    }
    .submenu{
      display: block;
      padding: 15px;
      padding-bottom: 0;
      a{
        display: block;
        @include fluid-type(20rem, 87.5rem, $font-size-base * 1.25, $font-size-base * 2.25);
        opacity: .6;
        &:not(:last-child){
          margin-bottom: 15px;
        }
        &.selected{
          color: var(--theme-primary);
          opacity: 1;
        }
      }
    }
  }

  .has-submenu{
    z-index: 10;
  }

  //
  @include mappy-bp(med) {
    &__collapse {
      transition: all .3s linear;
    }
  }

  // Navbar open
  body.navbar--opened & {
    background-color: var(--black);
    color: var(--white);
    position: fixed;
    @include mappy-bp(med) {
      background-color: var(--white);
      color: var(--black);
    }
    &__wrapper{
      &::before{
        opacity: 1;
      }
      &:after{
        background-color: hsl(var(--white-hsl), 0.2);
        @include mappy-bp(med) {
          background-color: hsl(var(--black-hsl), 0.2);
        }
      }
    }
    &__menu-preview, 
    &__lang{
        display: none;
    }
    &__brand{
      @include mappy-bp(med) {
        .brand_img{
          &.white{
            display: none;
          }
          &.black{
            display: block;
          }
        }
      }
    }
    &__toggler{
      @include mappy-bp(med) {
        color: var(--black);
      }
    }
    &__toggler-line {
      background: transparent!important;
      transition: none;
      &:before {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        transition: none;
      }
      &:after {
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        transition: none;
      }
    }
    .container{
      background-color: var(--black);
      color: var(--white);
      transition: none;
      @include mappy-bp(med) {
        background-color: var(--white);
        color: var(--black);
      }
    }
    &__collapse {
      visibility: visible;
      opacity: 1;
      transition: all .3s linear;
    }
  }

  // Navbar Fixed
  &.navbar--fixed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    .container{
      margin-left: auto;
      margin-right: auto;
    }
  }

  // Navbar slides out of view when scrolling down and slides back in when scrolling up
  &.navbar--fixed-showhide {
    &.out-stage {
      transform: translateY(-100%);
      transition: transform .3s cubic-bezier(.455, .03, .515, .955);
    }

    &.on-stage{
      background-color: var(--black);
      color: var(--white);
      pointer-events: all;
      opacity: 1;
      transform: translateY(0);
      filter: drop-shadow(0 20px 20px hsl(var(--black-hsl), .1));
      position: fixed;
      transition: transform .3s .3s cubic-bezier(.455, .03, .515, .955);
      @include mappy-bp(med) {
        background-color: var(--white);
        color: var(--black);
      }
      @include mappy-bp(large) {
        --navbar-height-large: 75px;
      }
      .navbar__brand{
        @include mappy-bp(med) {
          .brand_img{
            &.white{
              display: none;
            }
            &.black{
              display: block;
            }
          }
        }
      }
      .navbar__toggler{
        @include mappy-bp(med) {
          color: var(--black);
        }
      }
      .navbar__menu-preview{
        ul li{
          &.selected{
            color: var(--theme-primary);
            &:after{
              opacity: 0;
            }
          }
        }
      }
      .navbar__sx,
      .navbar__dx{
        .submenu{
          background-color: var(--black);
          color: var(--white);
          transform: translateX(-50%) translateY(calc(-1 * var(--mGap)));
          &:before{
            border-bottom-color: var(--black);
          }
          a{
            background-color: var(--black);
            border-bottom-color: hsl(var(--white-hsl), .15);
            @media (hover: hover) {
              &:hover{
                background-color: hsl(var(--white-hsl), .1);
              }
            }
          }
        }
      }
    }
  }

  &--spacer{
    height: var(--navbar-height-small);
    @include mappy-bp(med) {
      height: var(--navbar-height-med);
    }
    @include mappy-bp(large) {
      height: var(--navbar-height-large);
    }
  }

}

// Prevent vertical scrollbar when navbar is opened on smaller devices
body.navbar--opened,
body.navbar--search-opened {
  overflow: hidden;
}

body {
  &.lg-on{
    .navbar{
      z-index: 1000;
    }
  }
}
