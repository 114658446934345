// Home Highlights
.home-highlights {
  display: block;
  background-color: var(--black);
  background-size: 300% 200%;
  background-position-x: 80%;
  background-repeat: no-repeat;
  background-image: radial-gradient(circle, theme-var($--theme-primary) 0%, transparent 90%);
  *:not(.btn, .btn span, .btn strong) {
    color: var(--white);
  }
  .divisions-gate {
    isolation: isolate;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: var(--white);
      width: 100%;
      height: calc(var(--grid-gap) * 2 + 20%);
      z-index: -1;
      @include mappy-bp(med) {
        height: calc(var(--grid-gap) + 25%);
      }
    }
    .media > div{
      overflow: hidden;
    }
    .image {
      .label {
        align-items: flex-start;
        padding-top: calc(var(--grid-gap));
        * {
          margin: 0;
        }
      }
      @media (hover: hover) {
        img{
          transition: $transition-transform;
        }
        &:hover{
          img{
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
