// Main Form
.main-form {
  display: block;
  background-color: var(--black);
  padding-bottom: calc(var(--grid-gap) * 2);
  padding-top: calc(var(--grid-gap) * 2);
  @include mappy-bp(med) {
    padding-bottom: calc(var(--grid-gap) * 2);
    padding-top: calc(var(--grid-gap));
  }
  .thom-form{
    padding: 0 25px;
    @include mappy-bp(med) {
      padding: 0 50px;
    }
    &__input-textarea{
      min-height: initial;
      height: 48px;
      overflow: hidden;
    }
  }
}
