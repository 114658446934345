// Product Dett

// intro
.product-dett-intro {
  position: relative;
  isolation: isolate;
  background-color: var(--black);
  background-image: linear-gradient(40deg, var(--black) 40%, theme-var($--theme-primary) 100%);
  @include mappy-bp(med) {
    background-image: linear-gradient(20deg, var(--black) 35%, theme-var($--theme-primary) 70%);
    background-position: 0 calc(var(--navbar-height-med));
  }
  @include mappy-bp(large) {
    background-position: 0 calc(var(--navbar-height-large));
  }

  --minVideoBkHeight: 350px;
  --maxVideoBkHeight: 480px;
  .inner {
    position: relative;
    .text {
      z-index: 2;
      position: relative;
      overflow-y: hidden;
      @include mappy-bp(small med) {
        .breadcrumbs {
          margin-bottom: 20px;
        }
      }
      .block {
        position: relative;
        &--inner {
          @include mappy-bp(med) {
            width: 50%;
            padding-right: 15px;
            @include mappy-bp(large) {
              padding-right: 25px;
            }
          }
        }
        &:first-child {
          overflow-y: hidden;
          @include mappy-bp(med) {
            @include responsive-ratio(8, 3);
            max-height: var(--maxVideoBkHeight);
            min-height: var(--minVideoBkHeight);
            .inner--text {
              margin-top: auto;
            }
            & > div {
              height: 100%;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          .block--inner {
            @include mappy-bp(med) {
              height: 100%;
              display: flex;
              flex-direction: column;
              padding-bottom: calc(var(--grid-gap));
              padding-top: calc(var(--navbar-height-med) + 20px);
              @include mappy-bp(large) {
                padding-top: calc(var(--navbar-height-large) + 20px);
              }
            }
          }
        }
      }
    }
    .media {
      padding-top: calc(var(--navbar-height-small));
      @include mappy-bp(med) {
        display: block;
        padding-top: calc(var(--navbar-height-med));
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
      }
      @include mappy-bp(large) {
        padding-top: calc(var(--navbar-height-large));
      }
      .image {
        @include mappy-bp(med) {
          width: 50%;
          margin-left: auto;
        }
      }
    }
  }
  .media-bk {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @include responsive-ratio(8, 3);
    max-height: var(--maxVideoBkHeight);
    min-height: var(--minVideoBkHeight);
    z-index: -1;
    overflow: hidden;
    @include mappy-bp(med) {
      display: block;
    }
    & > div {
      height: 100%;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
  .text {
    color: var(--white);
  }
  .actions {
    position: relative;
    z-index: 1;
    margin-bottom: 40px;
    margin-top: 40px;
    border-top: 1px solid hsl(var(--white-hsl), .2);
    @include mappy-bp(large) {
      display: flex;
      border-top: 0;
      margin-bottom: calc(var(--grid-gap) / 2);
      margin-top: 0;
      transform: translateY(-50%);
    }
    .action {
      width: 100%;
      height: 56px;
      display: flex;
      align-items: center;
      font-size: 16px;
      isolation: isolate;
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid hsl(var(--white-hsl), .2);
      @include mappy-bp(large) {
        font-size: 18px;
        background-color: var(--white);
        border-bottom: 0;
        min-width: 240px;
        padding: 0 15px;
        text-align: center;
        justify-content: center;
        &:not(:last-child) {
          margin-right: 25px;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          background-color: var(--black);
        }
        @for $i from 1 through 50 {
          &:nth-child(#{$i}) {
            &:after {
              opacity: calc(1 - calc((#{$i} / 10) * 2.5));
              transition: background-color .3s linear;
            }
          }
        }
      }
      &.selected {
        &::after {
          background-color: var(--theme-primary);
          opacity: 1;
        }
      }
      @media (hover: hover) {
        &:not(.selected):hover {
          &:after {
            background-color: var(--theme-primary);
          }
        }
      }
    }
    &-desk {
      display: none;
      @include mappy-bp(large) {
        display: flex;
      }
    }
    &-mob {
      display: block;
      @include mappy-bp(large) {
        display: none;
      }
    }
  }
  .extra-image{
    margin-bottom: 30px;
    img{
      width: auto;
      max-width: 65%;
      @include mappy-bp(med) {
        max-width: 300px;
      }
    }
  }
}

// Plus
.product-plus {
  display: block;
  padding-bottom: calc(var(--grid-gap) * 2);
  padding-top: calc(var(--grid-gap) * 3);
  @include mappy-bp(med) {
    padding-bottom: calc(var(--grid-gap));
    padding-top: calc(var(--grid-gap) * 2);
  }
  &__wrap {
    overflow: hidden;
  }

  --gap: 20px;
  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
    overflow: hidden;
    @include mappy-bp(med large) {
      margin-left: calc(-1 * (var(--gap) * 2));
    }
    .item {
      color: var(--white);
      border-bottom: 1px solid hsl(var(--white-hsl), .5);
      padding: var(--gap) calc(var(--gap) / 2);
      margin-bottom: -1px;
      margin-top: 1px;
      position: relative;
      flex: 0 0 100%;
      @include mappy-bp(med) {
        flex: 0 0 calc(100% / 2);
        padding: calc(var(--gap) * 2) calc(var(--gap) * 2);
        &:after {
          content: "";
          position: absolute;
          right: -.5px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: calc(100% - (var(--gap) * 2));
          background-color: hsl(var(--white-hsl), .5);
        }
        &:nth-child(2n) {
          &:after {
            display: none;
          }
        }
      }
      @include mappy-bp(large) {
        flex: 0 0 calc(100% / 3);
        &:after {
          display: block;
        }
        &:nth-child(2n) {
          &:after {
            display: block;
          }
        }
        &:nth-child(3n) {
          &:after {
            display: none;
          }
        }
      }
      .title {
        display: flex;
        justify-content: space-between;
        .ico {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: var(--gap);
          svg {
            width: 20px;
            height: 20px;
          }
          img{
            width: 20px;
          }
        }
      }
    }
  }
}

// Highlights
.product-dett-highlights {
  display: block;
  background-color: var(--white);
  @include mappy-bp(med) {
    padding-bottom: calc(var(--grid-gap));
  }
  & > *:first-child {
    isolation: isolate;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--black);
      width: 100%;
      height: 30%;
      z-index: -1;
      @include mappy-bp(med) {
        height: 40%;
      }
    }
  }
}

// Image swap
.image-swap {
  display: block;
  @include mappy-bp(med) {
    display: flex;
    align-items: center;
    & > div {
      flex: 0 0 50%;
    }
  }
  &__viewer {
    position: relative;
    isolation: isolate;
    .default {
      z-index: -1;
    }
    .over {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      transition: opacity .2s linear;
      @include mappy-bp(med) {
        padding-right: 15px;
      }
      @include mappy-bp(med) {
        padding-right: 25px;
      }
      &.show {
        transition: opacity .4s ease-in-out;
        opacity: 1;
      }
    }
    @include mappy-bp(med) {
      padding-right: 15px;
    }
    @include mappy-bp(large) {
      padding-right: 25px;
    }
  }
  &__nav {
    padding: calc(var(--grid-gap)) 15px;
    [data-image] {
      cursor: pointer;
    }
    @include mappy-bp(med) {
      // display: grid;
      // grid-template-columns: repeat(2, 1fr);
      // gap: 8px;
      column-count: 2;
      column-gap: 2rem;
    }
    @include mappy-bp(large) {
      padding-left: 25px;
      padding-right: 25px;
    }
    .item {
      @include fluid-type(20rem, 87.5rem, $font-size-base * 1, $font-size-base * 1.5);
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}

// Dati tecnici
.techData {
  padding: calc(var(--grid-gap)) 10px;
  @include mappy-bp(med) {
    display: flex;
    flex-direction: row-reverse;
    padding: var(--grid-gap);
  }
  .datas {
    font-size: 13px;
    @include mappy-bp(med) {
      font-size: 18px;
      flex: 0 0 70%;
      padding-right: 25px;
    }
    table {
      tr {
        td {
          border-bottom: 1px solid currentColor;
          padding: 8px 10px;
          @include mappy-bp(med) {
            padding: 15px 25px;
          }
        }
      }
    }
  }
  .image {
    @include mappy-bp(small med) {
      padding: 0 calc(var(--grid-gap) * 2);
      margin-bottom: 15px;
      img {
        max-width: 250px;
        margin: 0 auto;
      }
    }
    @include mappy-bp(med) {
      flex: 0 0 30%;
      padding-left: 25px;
    }
  }
}

// configurator
.product-configurator {
  position: relative;
  overscroll-behavior: contain;
  isolation: isolate;
  &.cookie-accepted {
    .scrolling-propagation {
      .cta {
        pointer-events: all;
        opacity: 1;
      }
    }

    .cookie-alert {
      pointer-events: none;
      opacity: 0;
    }
  }
  &.loaded{
    .scrolling-propagation {
      .image {
        pointer-events: none;
        opacity: 0;
      }
      .cta {
        background-color: rgba(0, 0, 0, .25);
      }
    }
  }
  &.active {
    pointer-events: all;
    cursor: default;
    .scrolling-propagation {
      pointer-events: none;
    }
    .cta {
      pointer-events: none;
      opacity: 0;
    }
  }
  @include mappy-bp(med) {
    align-items: center;
    display: flex;
  }
  .scrolling-propagation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    background-color: transparent;
    opacity: 1;
    isolation: isolate;
    .image {
      position: absolute;
      transition: $transition-base;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: none !important;
      }
    }

    .cookie-alert {
      overflow: hidden;
      position: absolute;
      transition: $transition-base;
      z-index: 2;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-align: center;
      background-color: rgba(0, 0, 0, .4);
      color: $white;

      p {
        max-width: 500px;
        margin: 0 auto;
      }
    }

    .cta,
    .cta--blank {
      overflow: hidden;
      position: absolute;
      transition: $transition-base;
      z-index: 2;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .cta {
      @include mappy-bp(small med) {
        display: none;
      }
    }

    .cta--blank {
      @include mappy-bp(med) {
        display: none;
      }
    }
  }
  .iframe-content {
    display: block;
    width: 100%;
    @include responsive-ratio(16, 9);
    .cont {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    iframe {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}
