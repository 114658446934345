/**
 * Paragraph
 */

$paragraph-xs: .75 !default; // 11
$paragraph-sm: .875 !default; // 14
$paragraph-md: 1.125 !default; // 18
$paragraph-lg: 1.25 !default; // 20
$paragraph-xl: 1.5 !default; // 24
// $paragraph-margin-bottom: 1rem !default;

p,
.paragraph {
  @include fluid-type(20rem, 87.5rem, $font-size-base, $font-size-base); // 16
  &.xxs {
    @include fluid-type(
      20rem,
      87.5rem,
      $font-size-base * .75,
      $font-size-base * .75
    ); // 12
  }
  &.xs {
    @include fluid-type(
      20rem,
      87.5rem,
      $font-size-base * .875,
      $font-size-base * .875
    ); // 14
  }
  .s {
    @include fluid-type(
      20rem,
      87.5rem,
      $font-size-base * 1,
      $font-size-base * 1
    ); // 16
  }
  &.md {
    @include fluid-type(
      20rem,
      87.5rem,
      $font-size-base * 1,
      $font-size-base * 1.125
    ); // 16 - 18
  }
  &.lg {
    @include fluid-type(
      20rem,
      87.5rem,
      $font-size-base * 1.125,
      $font-size-base * 1.25
    ); // 18 - 20
  }
  &.xl {
    @include fluid-type(
      20rem,
      87.5rem,
      $font-size-base * 1.25,
      $font-size-base * 1.375
    ); // 20 - 22
  }
  &.xxl {
    @include fluid-type(
      20rem,
      87.5rem,
      $font-size-base * 1.125,
      $font-size-base * 1.5
    ); // 20 - 24
  }

  &.extra {
    @include fluid-type(
      20rem,
      87.5rem,
      $font-size-base * 1.5,
      $font-size-base * 2.25
    ); // 24 - 36
    line-height: 1.4;
  }
  &.mega {
    @include fluid-type(
      20rem,
      87.5rem,
      $font-size-base * 2,
      $font-size-base * 2.8125
    ); // 32 45px
  }

  a {
    color: currentColor;
    text-decoration: underline;
    @media (hover: hover) {
      &:hover {
        opacity: .6;
      }
    }
  }
}
