// Article
.article {
  padding-bottom: calc(var(--grid-gap) * 2);
  padding-top: calc(var(--grid-gap) * 2);
  $article: &;
  @include mappy-bp(med) {
    padding-bottom: calc(var(--grid-gap));
    padding-top: calc(var(--grid-gap));
  }
  .breadcrumbs {
    margin-bottom: 0;
  }
  .inner {
    display: block;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    @include mappy-bp(med) {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
    @include mappy-bp(small med) {
      & > *:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    .media {
      display: block;
    }
    .text {
      display: flex;
      flex-direction: column;
      & > *:not(:last-child, h3) {
        margin-bottom: 20px;
        @include mappy-bp(med) {
          margin-bottom: 40px;
        }
        @include mappy-bp(large) {
          margin-bottom: 50px;
        }
        & + .btn{
          margin-top: 0;
        }
      }
      &.center {
        align-items: center;
      }
      &.justify-center {
        justify-content: center;
        & > * {
          max-width: calc(100% - var(--grid-gap) * 2);
          margin-left: auto;
          margin-right: auto;
        }
      }
      p {
        max-width: 900px;
      }
      ol {
        // list-style-type: disc;
        li {
          // background-image: url($cirlce-fill);
          // background-size: 5px 5px;
          // background-repeat: no-repeat;
          // background-position: left center;
          // padding-left: 10px;
          display: flex;
          // align-items: center;
          &:before {
            content: "";
            width: 5px;
            height: 5px;
            flex: 0 0 5px;
            border-radius: 50%;
            background-color: currentColor;
            margin-right: 10px;
            margin-top: .8rem;
          }
        }
      }
      & + #{$article}--list {
        margin-top: 50px;
      }
      .btn {
        margin-top: 30px;
        span{
          color: var(--white);
        }
      }
    }
    &__flex {
      @include mappy-bp(med) {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        .media {
          flex: 0 0 50%;
          @include mappy-bp(med) {
            margin-bottom: 0;
          }
        }
        .text {
          flex: 0 0 50%;
        }
        & > *:first-child {
          padding-right: 15px;
        }
        & > *:last-child {
          padding-left: 15px;
        }
        &.reverse {
          flex-direction: row-reverse;
          & > *:first-child {
            padding-right: 0;
            padding-left: 15px;
          }
          & > *:last-child {
            padding-left: 0;
            padding-right: 15px;
          }
        }
        &.align-c {
          align-items: center;
        }
      }
      @include mappy-bp(large) {
        & > *:first-child {
          padding-right: 25px;
        }
        & > *:last-child {
          padding-left: 25px;
        }
        &.reverse {
          flex-direction: row-reverse;
          & > *:first-child {
            padding-left: 25px;
          }
          & > *:last-child {
            padding-right: 25px;
          }
        }
      }
    }
  }
  &--list {
    display: block;
    .item {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .image {
        width: 20%;
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
        padding: 0 20px;
        @include mappy-bp(med) {
          padding: 0 40px;
        }
      }
    }
  }
}

.video--controls {
  position: absolute;
  bottom: 10px;
  right: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px;
  padding: 10px;
  transition: all .3s linear;
  overflow: hidden;
  @include mappy-bp(large) {
    bottom: 20px;
    opacity: 0;
  }
  // .progress-bar{
  //   background-color: rgba($color-three, .2);
  //   position: absolute;
  //   z-index: 1;
  //   left: 0;
  //   width: 0;
  //   height: 100%;
  //   top: 0;

  // }
  .btn {
    --height: 35px;
    margin: 0 5px;
    width: var(--height);
    flex-basis: var(--height);
    background-color: rgba(var(--black), .5);
    backdrop-filter: blur(40px);
    border: 2px solid currentColor;
    color: var(--theme-primary);
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    transition: all .3s linear;
    z-index: 10;
    max-width: none;
    svg {
      height: calc(#{var(--height)} / 2);
      width: calc(#{var(--height)} / 2);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity .3s linear;
    }
    @include mappy-bp(med) {
      --height: 45px;
      svg {
        // height: 20px;
        // width: 20px;
      }
      &:hover {
        color: var(--white);
      }
    }

    &.volume {
      .volume-up {
        opacity: 0;
      }
      .volume-off {
        opacity: 1;
      }
      &.on {
        .volume-up {
          opacity: 1;
        }
        .volume-off {
          opacity: 0;
        }
      }
    }

    &.state {
      .play {
        opacity: 0;
      }
      .pause {
        opacity: 1;
      }
      &.stop {
        .play {
          opacity: 1;
        }
        .pause {
          opacity: 0;
        }
      }
    }
  }
}
.is-playing {
  .video--controls {
    opacity: 1;
  }
}
