/**
 * Animations
 */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes pulse {
  0% {
    transform: scale(.95);
    filter: drop-shadow(0 0 0 hsl(var(--black-hsl), .4));
  }

  70% {
    transform: scale(1);
    filter: drop-shadow(0 0 20px hsl(var(--black-hsl), .5));
  }

  100% {
    transform: scale(.95);
    filter: drop-shadow(0 0 0 hsl(var(--black-hsl), 0));
  }
}

@keyframes bouncealpha {
  0% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }

  25% {
    opacity: 0;
    transform: translateX(150%) scale(.9);
  }

  26% {
    opacity: 0;
    transform: translateX(-150%) scale(.9);
  }

  55% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@keyframes bouncealphaleft {
  0% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }

  25% {
    opacity: 0;
    transform: translateX(-200%) scale(.9);
  }

  26% {
    opacity: 0;
    transform: translateX(200%) scale(.9);
  }

  55% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

$animation-bounce-alpha: bouncealpha 1.4s infinite linear;
$animation-bounce-alpha-left: bouncealphaleft 1.4s infinite linear;
