// Event Alert
.event-alert {
  background-color: theme-var(--theme-primary);
  color: var(--white);
  // height: 120px;
  padding: 30px 0;
  display: flex;
  align-items: center;
  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .text {
    @include mappy-bp(small med) {
      padding-left: 0;
      font-size: 14px;
    }
    @include mappy-bp(med) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      & > div {
        max-width: 80%;
      }
    }
  }
  .btn {
    background-color: var(--black);
    span {
      color: var(--white);
    }
    @include mappy-bp(med) {
      margin-left: auto;
    }
    @media (hover: hover) {
      &:hover {
        span {
          filter: invert(0);
        }
      }
    }
  }
}
