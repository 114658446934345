/**
 * Headings
 */

$headings-margin-bottom: .5rem !default;
$headings-line-height-mob: 1 !default;

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.title-font,
.title {
  line-height: $headings-line-height-mob;
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
  @include mappy-bp(med) {
    line-height: 1.1;
  }

  &.xxs{
    @include fluid-type(20rem, 87.5rem, $font-size-base * 1.125, $font-size-base * 1.25); // 18 - 20
  }
  &.xs{
    @include fluid-type(20rem, 87.5rem, $font-size-base * 1.25, $font-size-base * 1.5); // 20 24px
  }
  &.s{
    @include fluid-type(20rem, 87.5rem, $font-size-base * 1.5, $font-size-base * 1.875); // 24 - 30
  }
  &.md{
    @include fluid-type(20rem, 87.5rem, $font-size-base * 1.875, $font-size-base * 2.25); // 30 36px
  }
  &.lg{
    @include fluid-type(20rem, 87.5rem, $font-size-base * 2.25, $font-size-base * 3); // 36 - 48
  }
  &.xl{
    @include fluid-type(20rem, 87.5rem, $font-size-base * 2.375, $font-size-base * 3.375); // 38 - 60
  }
  &.xxl{
    @include fluid-type(20rem, 87.5rem, $font-size-base * 2, $font-size-base * 4.063); // 35 - 72
  }

  strong,
  b{
    font-weight: 900;
  }
}
