// Form
.thom-form {
  display: block;
  position: relative;
  overflow: hidden;
  $this: &;

  &--loader {
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
    pointer-events: none;
    transition: $transition-base;
    .spinner-circle {
      width: 50px;
      height: 50px;
    }
    .circle__svg-circle {
      stroke: theme-var($--theme-background);
      stroke-width: 4;
    }
  }

  &.loading {
    #{$this}--loader {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-response {
    position: relative;
    transition: $transition-base;
    &.is-hidden {
      opacity: 0;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    &-center {
      justify-content: center;
    }
    @include mappy-bp(small med) {
      flex-wrap: wrap;
    }
    @include mappy-bp(med) {
      margin-left: calc(-1 * var(--form-col-gap) / 2);
      margin-right: calc(-1 * var(--form-col-gap) / 2);
    }
    a {
      color: var(--theme-primary) !important;
      font-size: 14px;
      padding: 0 10px;
      text-decoration: underline;
    }
    &:last-child {
      margin-top: 40px;
    }
  }
  &__column {
    margin: 0;
    margin: 10px 0;
    width: 100%;
    position: relative;
    &--full {
      width: 100%;
    }
    @include mappy-bp(med) {
      display: flex;
      flex-direction: column;
      margin: 10px calc(var(--form-col-gap) / 2);
      width: calc(50% - var(--form-col-gap) * 4);
      &--full {
        width: 100%;
      }
      &--w-10 {
        width: calc(10% - var(--form-col-gap));
      }
      &--w-20 {
        width: calc(20% - var(--form-col-gap));
      }
      &--w-25 {
        width: calc((100% / 3) - var(--form-col-gap));
      }
      &--w-30 {
        width: calc(30% - var(--form-col-gap));
      }
      &--w-1-3 {
        width: calc(100% / 3 - var(--form-col-gap));
      }
      &--w-40 {
        width: calc(40% - var(--form-col-gap));
      }
      &--w-60 {
        width: calc(60% - var(--form-col-gap));
      }
      &--w-70 {
        width: calc(70% - var(--form-col-gap));
      }
      &--w-80 {
        width: calc(80% - var(--form-col-gap));
      }
    }

    @include mappy-bp(large) {
      &--w-25 {
        width: calc(25% - var(--form-col-gap));
      }
    }
  }

  &__label {
    color: var(--white);
    display: block;
    // @include fluid-type(20rem, 87.5rem, $font-size-base * 1.125, $font-size-base * 1.25); // 18 - 20
    font-size: 14px;
    // margin-bottom: 5px;
    padding: 0;
    text-align: left;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    top: 20px;
    transform: translateY(-50%);
    width: 100%;
    pointer-events: none;
    transition: transform .3s;
    transform-origin: 0 50%;
    & + .thom-form__checkcont {
      margin-top: 5px;
    }
  }

  &__input-text,
  &__input-textarea,
  &__select {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid hsl(var(--white-hsl), .5);
    color: var(--white);
    display: block;
    font-size: 14px;
    height: 48px;
    padding: 0;
    width: 100%;
    appearance: none;
    &::placeholder {
      color: currentColor;
    }
    &:focus {
      outline: none;
      &::placeholder {
        color: hsl(var(--white-hsl), .5);
      }
      & ~ .thom-form__label {
        transform: translateY(-30px) scale(.75);
      }
    }
    &:not(:placeholder-shown) ~ .thom-form__label {
      transform: translateY(-30px) scale(.75);
    }
    &:-webkit-autofill,
    &:autofill {
      background-color: transparent !important;
      color: var(--white) !important;
      box-shadow: none !important;
    }
    &.error + .error {
      @include row;
      color: var(--color-accent);
      margin-top: 20px;
      padding: 0;
    }
  }

  // input,
  // textarea,
  // select {
  //   &:autofill,
  //   &:-webkit-autofill {
  //     &,
  //     &:hover,
  //     &:focus,
  //     &:active {
  //       -webkit-text-fill-color: var(--white);
  //       -webkit-box-shadow: none;
  //       box-shadow: none;
  //       background-color: var(--black) !important;
  //       color: var(--white);
  //     }
  //   }
  // }

  input,
  textarea {
    &:read-only {
      background-color: $light-grey;
      color: hsl(var(--theme-primary-hsl), .7);
    }
  }

  &__select[multiple] {
    background: none;
    height: auto;
    padding-top: 10px;
  }

  &__input-textarea {
    height: 200px;
    padding-top: 15px;
    padding-bottom: 15px;
    resize: none;
    @include mappy-bp(med) {
      min-height: 200px;
      height: 100%;
    }
  }

  &__select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: calc(100% - 10px);
    background-size: 16px 16px;
    // padding-right: 36px;
  }

  .select {
    &:after {
      background-image: linear-gradient(to right, hsl(var(--theme-background-hsl), 1) 70%, hsl(var(--black-hsl), 0)) !important;
      width: 60px;
    }
  }

  &__submit {
    --height: 56px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border: 0;
    border-radius: 0;
    background-color: theme-var(--theme-primary);
    color: var(--black);
    cursor: pointer;
    font-size: 18px;
    height: var(--height);
    margin: 0;
    margin-left: auto;
    text-align: center;
    width: auto;
    position: relative;
    max-width: fit-content;
    @media (hover: hover) {
      transition: $transition-base, background-position .25s cubic-bezier(.455, .03, .515, .955);
    }
    @include mappy-bp(med) {
      height: var(--height);
    }
    & > span {
      padding: 0 30px;
    }
    .icons-wrap {
      border-radius: 50%;
      background-color: transparent;
      color: var(--white);
      width: var(--height);
      height: var(--height);
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
    svg {
      height: 50%;
      width: 50%;
    }
    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
    .spin {
      border-radius: 50%;
      background-color: hsl(var(--theme-primary-hsl));
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      opacity: 0;
      transform: $transition-base;
      svg {
        opacity: .8;
        height: 50%;
        width: 50%;
      }
    }
    @media (hover: hover) {
      &:hover {
        color: var(--white);
        background-position: 100% 0;
      }
    }
    &.clicked {
      .spin {
        opacity: 1;
        svg {
          animation: spin 1.5s linear infinite;
        }
      }
    }
  }

  &__checkgroup {
    @include mappy-bp(med) {
      align-items: center;
      display: flex;
      .thom-form__checkcont {
        margin: 0;
        &:not(:last-child) {
          margin-right: 10px;
        }
        .thom-form__label {
          align-items: center;
        }
        &__customcheckbox {
          margin-top: 0;
        }
      }
    }
  }

  &__checkcont {
    display: block;
    margin-bottom: 20px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
    label {
      align-items: center;
      display: flex;
      margin: 0;
      padding: 0;
      font-size: 14px;
      text-transform: none;
      width: auto;
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      pointer-events: all;
    }
    &__label {
      color: var(--white);
      margin: 0;
      padding: 0 7.5px;
      position: relative;
      font-size: 12px;
      a {
        color: var(--white) !important;
        padding: 0;
      }
    }
    &__checkbox {
      border: 0;
      display: block;
      height: 16px;
      left: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      visibility: hidden;
      z-index: 10;
      width: 16px;
    }
    &__customcheckbox {
      border: 1px solid var(--white);
      height: 18px;
      position: relative;
      width: 18px;
      flex: 0 0 18px;
      &:after {
        background-color: var(--white);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: "";
        height: 8px;
        margin: 0;
        opacity: 0;
        transition: $transition-base;
        width: 8px;
      }
      &.radio {
        background-color: var(--white);
        border: 0;
        border-radius: 50rem;
        box-shadow: 0 0 50px hsl(var(--black-hsl), .3);
        height: 30px;
        width: 30px;
        flex: 0 0 30px;
        &:after {
          border-radius: 50rem;
          height: 15px;
          width: 15px;
        }
      }
    }
    &__checkbox:checked ~ &__customcheckbox {
      &:after {
        opacity: 1;
      }
    }
  }

  &-selectfile {
    display: block;
    position: relative;
    & > span {
      display: block;
    }
    &__input_file {
      background-color: transparent;
      height: 100%;
      left: 0;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
    }
    &__label {
      border-bottom: 1px solid var(--white);
      color: var(--white);
      cursor: pointer;
      align-items: center;
      display: flex;
      justify-content: space-between;
      height: 40px;
      margin-top: 0;
      width: 100%;
      padding: 0 4px;
      font-size: $font-size-base * .75;
      @include mappy-bp(med) {
        transition: $transition-base;
      }
    }
    i {
      position: relative;
      width: 2vw;
      &:before,
      &:after {
        content: "";
        background-color: var(--black);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
      &.icon-plus {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        &:before {
          width: .2vw;
          height: 2vw;
        }
        &:after {
          width: 2vw;
          height: .2vw;
        }
      }
    }

    .ico {
      img {
        width: 12px;
      }
    }
    &:focus &__label,
    &:hover &__label {
      opacity: .8;
    }
  }

  .input-validation-error {
    // background-color: var(--color-accent);
    border-color: var(--color-accent);
  }

  &__checkcont__checkbox.input-validation-error ~ .thom-form__checkcont__label,
  &__checkcont__checkbox.input-validation-error ~ .thom-form__checkcont__label a {
    color: var(--color-accent);
  }
  &__checkcont__checkbox.input-validation-error ~ &__checkcont__customcheckbox {
    border-color: var(--color-accent);
  }

  &__recaptchaCont {
    margin-bottom: calc(var(--grid-gap) * 2);
    overflow: hidden;
    @include mappy-bp(med) {
      margin-bottom: calc(var(--grid-gap));
    }
  }
  &__recaptcha {
    width: 100%;
    & > div {
      width: 100%;
    }
  }

  .mtcaptcha {
    @include mappy-bp(med) {
      float: right;
    }
  }

  &__last {
    display: block;
    margin-bottom: calc(var(--grid-gap) / 2);
    padding: 0 calc(var(--grid-gap) / 2);
    width: 100%;
  }

  &__error {
    @include row;
    color: var(--color-accent);
    display: none;
    // margin-bottom: 20px;
    padding: 0;
  }
  .inv-recaptcha-holder {
    position: relative;
    margin-top: 25px;
  }
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus {
//   // -webkit-text-fill-color: var(--white);
//   // -webkit-box-shadow: 0 0 0px 20rem var(--black) inset;
//   box-shadow: 0 0 0 0 rgba(0,0,0,0) inset;
// }
