/**
 * Media
 */

// Fluid images for responsive purposes.

img {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
  user-select: none;
}

// If a `width` and/or `height` attribute have been explicitly defined, let’s not make the image fluid.

img[width],
img[height] {
  max-width: none;
}

figure {
  margin: 0;
}

object,
iframe,
embed,
canvas,
video,
audio {
  max-width: 100%;
}

.media{
  isolation: isolate;
  & > div{
    position: relative;
  }
  .image{
    overflow: hidden;
    position: relative;
    transition: $transition-base;
    z-index: 10;
  }
  .video{
    overflow: hidden;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .video--item,
  iframe,
  object,
  embed {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: none !important;
  }
  &.is-playing{
    .image{
      opacity: 0;
      pointer-events: none;
    }
  }
  .label{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    padding: 0 calc(var(--grid-gap));
    color: var(--white);
  }
}
