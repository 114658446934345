/**
 * Transitions
 */

$transition-base: all .35s ease-in-out !default;
$transition-transform: transform .5s cubic-bezier(.455, .03, .515, .955) !default;

.page-transition {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 15000;
  pointer-events: none;
  overflow: hidden;
  background-color: hsl(var(--theme-primary-hsl));
  opacity: 0;
  animation-name: page-transition-out;
  animation-duration: .35s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-delay: 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    position: fixed;
    z-index: 15030;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    img {
      height: 65px;
    }
  }
  &.show {
    pointer-events: all;
    opacity: 1;
    animation: none;
  }
  &.animIn {
    pointer-events: all;
    animation-name: page-transition-in;
    animation-duration: .35s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    animation-delay: 0s;
  }
}

@keyframes page-transition-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes page-transition-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
