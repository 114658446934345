/**
 * Buttons
 */

.btn {
  --height: 45px;
  background-color: theme-var($--theme-primary);
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: var(--height);
  position: relative;
  user-select: none;
  line-height: 1.1;
  z-index: 1;
  max-width: fit-content;
  @include mappy-bp(med) {
    --height: 56px;
    font-size: 18px;
  }
  span{
    display: block;
    padding: 0 25px;
    transition: $transition-base;
    line-height: normal;
    color: var(--black);
    @include mappy-bp(med) {
      padding: 0 30px;
    }
    @include mappy-bp(large) {
      padding: 0 30px;
    }
  }
  .ico{
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: var(--white);
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    @include mappy-bp(med) {
      width: 76px;
      height: 76px;
      flex: 0 0 76px;
    }
  }
  &:focus {
    outline: 0;
    text-decoration: none;
  }
  &__outline{
    background-color: transparent;
    border: 2px solid currentColor;
    cursor: pointer;
    @include mappy-bp(med) {
      transition: $transition-base;
    }
    span{
      display: block;
      padding: 0 30px;
      transition: $transition-base;
      width: 100%;
      line-height: normal;
    }
    &:hover {
      color: theme-var($--theme-primary);
    }
  }
  &:hover {
    span{
      filter: invert(1);
    }
  }
}

// Button Gallery
.btn-gallery,
.btn-video {
  cursor: pointer;
}

.button {
  --height: 45px;
  background-color: hsl(var(--white-hsl), .06);
  border: 2px solid currentColor;
  align-items: center;
  color: var(--white);
  cursor: pointer;
  display: inline-flex;
  height: var(--height);
  padding: 0 30px;
  border-radius: calc(var(--height) / 2);
  user-select: none;
  line-height: 1.1;
  text-align: center;
  z-index: 1;
  @include mappy-bp(med) {
    --height: 50px;
    height: var(--height);
  }
  &__fill {
    background-color: var(--white);
    color: var(--black);
    border-color: var(--white);
  }
}

.close{
  border: 2px solid var(--black);
  border-radius: 50%;
  color: var(--black);
  cursor: pointer;
  height: 70px;
  width: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: .6;
  &:after{
    content: '';
    background-image: url($ico-close);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 30px;
    height: 30px;
  }
  @include mappy-bp(large) {
    transition: $transition-base;
    &:hover{
      opacity: 1;
    }
  }
}
